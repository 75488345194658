// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$gp-palette: (
  50: #fbfaf9,
  100: #f3f1ed,
  200: #e7e2da,
  300: #d6d0c2,
  400: #502717,
  500: #371b10,
  600: #24110a,
  700: white,
  800: #212529,
  900: white,
  contrast: (
    50: rgba(black, 0.5),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: rgba(black, 0.87),
    900: white,
  ),
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gp-qr-code-primary: mat.define-palette($gp-palette);
$gp-qr-code-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gp-qr-code-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gp-qr-code-theme: mat.define-light-theme(
  (
    color: (
      primary: $gp-qr-code-primary,
      accent: $gp-qr-code-accent,
      warn: $gp-qr-code-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gp-qr-code-theme);
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css");
// regular style toast
// @import "ngx-toastr/toastr";

/* You can add global styles to this file, and also import other style files */

.dense-4 {
  @include mat.all-component-densities(-4);
}

* {
  font-family: "Manrope", sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: "Mulish", "Roboto", "Helvetica Neue", sans-serif;
  // overflow: hidden;
}
.mat-mdc-tab-body-content {
  padding: 20px;
}
.mat-mdc-form-field {
  width: 100%;
  background-color: #fff;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}
.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary {
  border-radius: 16px;
}
.mat-mdc-outlined-button:not(:disabled){
  border-color: mat.get-color-from-palette($gp-palette, 400) !important;
  color: mat.get-color-from-palette($gp-palette, 400) !important;
}
header{
  padding: 10px;
}
// span{
//   color:#353B41
// }
span.lnr {
  color: #fff;
}
.item {
  display: flex;
  padding: 10px;
  border: solid 1px #f4f7fa;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  .img {
    width: 40px;
    height: 40px;
    background-color: #eff2f5;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  small {
    display: block;
  }
  .datetime {
    text-align: right;
  }
  & + .item {
    margin-top: 15px;
  }
}

.icon-valid {
  padding: 10px;
  width: 50px;
  width: 50px;
  background-color: #28ca9e;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 36px;
  ng-icon{
    display: flex;
    width: 100%;
    height: 100%;
  }
}
.icon-invalid {
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: #f35625;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 36px;
  ng-icon{
    display: flex;
    width: 100%;
    height: 100%;
  }
}
.white-icon {
  color: white;
}
/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg path {
  stroke: white;
}
.white-icon svg circle{
  stroke: white;

}

.mat-mdc-unelevated-button:disabled {
  background-color: #eff2f5;
  color: #505862;
  font-weight: 600;
}
.mdc-button {
  font-family: "Mulish", "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
h4{
  font-size: 24px;
  font-weight: 700;
  color: mat.get-color-from-palette($gp-palette, 400) !important;
}
h3 {
  font-weight: 700;
  font-family: "Mulish", "Roboto", "Helvetica Neue", sans-serif;
  color: #353b41;
  margin: 20px 0;
}
.element{
  position:absolute;
  top:0;
  bottom: 100px;
  width:100%;
  height:70vh;
  background-image: url('./assets/img/Video.png');
  background-repeat: no-repeat;
  background-position: center;
  // background-color:#00000099 ;
  // -webkit-clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
  // clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 15%, 80% 15%, 80% 55%, 15% 55%, 15% 100%, 100% 100%, 100% 0%);

}
.scan {
  max-height: 70vh;
  width: 100vw;
  object-fit: contain;
}
.mat-mdc-unelevated-button:not(:disabled){
  color: #fff!important;
}

.logo{
  margin: 20px 0;
  height: 56px;
  img{
    height: 100%;
    object-fit: cover;
  }
}

.mat-mdc-tab-label-container{
  background-color: #F8F9FA;
}